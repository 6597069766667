import { usePathname, useSearchParams } from 'next/navigation';
import { LANGUAGES } from 'src/data/constants';
import useLang from 'src/services/language/useLang';
import useLocalize from 'src/services/localize/useLocalize';
import { useTheme } from 'src/ui-kit/theme';
import savePreferredLanguage from 'src/utils/js-helpers/savePreferredLanguage';
import makeSearchString from 'src/utils/system/makeSearchString';
import { getQueryObjectFromSearchParams } from 'src/utils/system/searchParamsHelpers';

const useSwitchLocale = () => {
  const currentLanguage = useLang();
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const theme = useTheme();
  const localize = useLocalize();

  const query = getQueryObjectFromSearchParams(searchParams);
  const searchString = makeSearchString(query);
  const pathRegExp = new RegExp(`^/${currentLanguage}`);

  const links = LANGUAGES.map(language => {
    const newLangSegment = `/${language}`;
    const href = (pathname?.replace(pathRegExp, newLangSegment) || newLangSegment) + searchString;

    return {
      language,
      href,
      onClick: () => savePreferredLanguage(language),
      title: localize(`lang_${language}`),
      isActive: language === currentLanguage,
    };
  });

  return {
    links,
    currentLanguage,
    theme,
    localize,
  };
};

export default useSwitchLocale;
