import { useModals } from "src/modules/modals";
import useFavoritesService from "src/services/favorites/useFavoritesService";
import useLocalize from "src/services/localize/useLocalize";
import useNotificationsService from "src/services/notifications/useNotificationsService";
import IconFont from "src/ui-kit/IconFont";
import getFavoriteNotificationText from "../../helpers/getFavoriteNotificationText";
import Notification from "../Notification/Notification";
import NotificationButton from "../NotificationButton/NotificationButton";
import { sendChangeButtonClick } from "../../helpers/analytics";

const FavoriteNotification = () => {
  const localize = useLocalize();
  const { state, closeNotification } = useNotificationsService();
  const { lists } = useFavoritesService();
  const { openModal } = useModals();
  const isOpen = state.type === 'favorite';

  if (!isOpen) {
    return null;
  }

  const { listIds, actionType, product } = state.data;

  const message = getFavoriteNotificationText({
    lists,
    listIds,
    actionType,
  }, localize);

  const handleChangeClick = () => {
    openModal('addToListModal', { product });
    sendChangeButtonClick(actionType);
  };

  return (
    <Notification
      isOpen={isOpen}
      message={message}
      onClose={closeNotification}
      dataMarker="notification_favorite"
      slotButton={
        <NotificationButton
          label={localize('general.change')}
          dataMarker='change'
          onClick={handleChangeClick}
        />
      }
      slotIcon={
        <IconFont
          name="heart-full"
          color='error'
          size='14px'
        />
      }
    />
  );
};

export default FavoriteNotification;
