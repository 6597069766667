import { createFilterParam } from "../catalog/createFilterParam";

export function processMultipleParams(key: string, values: string[]): string {
  return values.reduce((string, val, index) => {
    const starter = index === 0 ? '' : '&';
    return string + starter + createFilterParam(key, val, "=");
  }, '');
}

export interface QueryObject {
  [key: string]: string | string[] | undefined;
}

export const excludeParams = ['lang', 'categoryId', 'firstLevelFilter'];

export default function makeSearchString(queryObject: QueryObject = {}): string {
  const searchString = '';
  return Object.keys(queryObject)
    .reduce((string, key) => {
      const value = queryObject[key];
      if (excludeParams.indexOf(key) === -1 && value !== undefined) {
        const starter = string === '' ? '?' : '&';
        if (Array.isArray(value)) {
          return string + starter + processMultipleParams(key, value);
        }

        return string + starter + createFilterParam(key, value, "=");
      }

      return string;
    }, searchString);
}
