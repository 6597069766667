import Button from "src/ui-kit/Button";
import { Typography } from "src/ui-kit/Typography";

interface Props {
  onClick(): void;
  dataMarker?: string;
  label: string;
}

const NotificationButton = (props: Props) => {
  const {
    onClick,
    dataMarker,
    label,
  } = props;

  return (
    <Button
      variant="ghost"
      onClick={onClick}
      dataMarker={dataMarker}
    >
      <Typography
        variant='caption1'
        color='inherit'>
        {label}
      </Typography>
    </Button>
  );
};

export default NotificationButton;
