import { useProductPageExperiment } from "src/utils/catalog/useProductPageExperiment";
import useProductRetailInfoLists, { ProductData } from "../../hooks/useProductRetailInfoLists";
import RetailsInfo, { CommonRetailsInfoProps } from "../RetailsInfo/RetailsInfo";

export type ProductRetailsInfoProps = CommonRetailsInfoProps & { productData: ProductData };

const ProductRetailsInfo = (props: ProductRetailsInfoProps) => {
  const { productData, ...commonProps } = props;
  const productRetailsInfo = useProductRetailInfoLists(productData);
  const { experimentIsActive } = useProductPageExperiment();

  return <RetailsInfo {...commonProps} {...productRetailsInfo} withRowCards={experimentIsActive} />;
};

export default ProductRetailsInfo;
