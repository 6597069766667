import { useContext } from "react";
import NotificationServiceContext from "./NotificationsServiceContext";

const useNotificationsService = () => {
  const context = useContext(NotificationServiceContext);

  if (!context) {
    throw new Error('NotificationsServiceContext is used without provider');
  }

  return context;
};

export default useNotificationsService;
