import classNames from 'classnames';
import { SwitchLocaleStyled } from './SwitchLocale.styles';
import useSwitchLocale from './useSwitchLocale';

const SwitchLocale = () => {
  const {
    theme,
    links,
  } = useSwitchLocale();

  return (
    <SwitchLocaleStyled
      className='SwitchLocale'
      theme={theme}
    >
      {
        links.map(link => (
          <a
            key={link.language}
            href={link.href}
            onClick={link.onClick}
            className={classNames(
              'SwitchLocale__link',
              {
                active: link.isActive,
              },
            )}
          >
            <span className='SwitchLocale__linkText'>{link.title}</span>
          </a>
        ))
      }
    </SwitchLocaleStyled>
  );
};

export default SwitchLocale;
