import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { useChains } from "src/api/swr";
import { GET_PRODUCT_RETAIL_CARDS_INFO } from "src/graphql/queries";
import { getStringFromCoords } from "src/modules/delivery";
import useLang from "src/services/language/useLang";
import useLocalize from "src/services/localize/useLocalize";
import useUserDeliveryService from "src/services/userDelivery/useUserDeliveryService";
import { getNPSettlementIdFromUserDelivery } from "src/utils/delivery/novaPoshtaHelpers";
import { getRetailInfoListsForProduct } from "../helpers/getRetailInfoListsForProduct";
import getRetailInfoQueryStringForLink from "../helpers/getRetailInfoQueryStringForLink";
import { RetailInfoLists } from "../types";
import useStoresByUserDelivery from "./useStoresByUserDelivery";

export interface ProductData {
  ean: string;
  tags?: string[];
  slug: string;
}

const useProductRetailInfoLists = (params: ProductData) => {
  const {
    ean: productEan,
    tags = [],
  } = params;
  const language = useLang();
  const localize = useLocalize();
  const { chains } = useChains();
  const { stores, storesAreFetching } = useStoresByUserDelivery();
  const { userDelivery, userDeliveryIsReady } = useUserDeliveryService();
  const deliveryAsQueryStringForLink = useMemo(() => {
    return getRetailInfoQueryStringForLink(userDelivery);
  }, [userDelivery]);
  const chainIds = chains.map(chain => chain.id);
  const storeIds = stores.map(store => store.id);
  const clientCoords = userDelivery?.type === 'plan'
    ? getStringFromCoords(userDelivery.geocodedAddress.coords)
    : '';
  const clientSettlementId = getNPSettlementIdFromUserDelivery(userDelivery);

  const dataAreReady = chains.length > 0 && !storesAreFetching && userDeliveryIsReady;

  const { data: retailCardsData, loading } = useQuery(GET_PRODUCT_RETAIL_CARDS_INFO, {
    variables: {
      chainIds,
      storeIds,
      //deliveryMethod must be not nullable
      deliveryMethod: userDelivery?.type || 'plan',
      clientCoords,
      clientSettlementId,
      productEan,
      productTags: tags,
    },
    skip: !dataAreReady,
  });

  const retailInfoLists: RetailInfoLists = getRetailInfoListsForProduct({
    retailCardsData,
    //deliveryType may be nullable to display cards without slots
    deliveryType: userDelivery?.type || null,
    deliveryAsQueryStringForLink,
    retailChains: chains,
    productData: params,
    language,
    localize,
  });

  const listsAreEmpty = retailInfoLists.available.length === 0 && retailInfoLists.unavailable.length === 0;

  return {
    retailListsAreLoading: loading,
    skeletonIsDisplayed: listsAreEmpty,
    retailInfoLists,
  };
};

export default useProductRetailInfoLists;
