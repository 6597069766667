import { getStringFromCoords, UserDeliveryType } from "src/modules/delivery";
import { getDeliveryTypeIsNP } from "src/utils/delivery/novaPoshtaHelpers";

export const getStoresRequestStringFromUserDelivery = (userDelivery: UserDeliveryType): string => {
  if (userDelivery) {
    const type = userDelivery.type;

    if (type === 'plan') {
      const {
        geocodedAddress: { coords },
        deliveryServiceId,
      } = userDelivery;

      const coordsString = getStringFromCoords(coords);
      return `?coords=${coordsString}&delivery_type=${type}&delivery_service=${deliveryServiceId}`;
    }

    if (type === 'pickup') {
      const {
        deliveryServiceId,
        type,
      } = userDelivery;

      return `?delivery_type=${type}&delivery_service=${deliveryServiceId}`;
    }

    if(getDeliveryTypeIsNP(type)) {
      return `?delivery_type=${type}`;
    }
  }

  return '?default=true';
};

