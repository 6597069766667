import { ReadonlyURLSearchParams } from "next/navigation";
import { ParamsObject } from "src/app/types";

export const getQueryObjectFromSearchParams = (searchParams: ReadonlyURLSearchParams | null): ParamsObject => {
  const paramsObject = Array.from(searchParams?.keys() || []).reduce((acc, key) => {
    const values = searchParams?.getAll(key) || [];
    acc[key] = values.length > 1 ? values : values[0];
    return acc;
  }, {});

  return paramsObject;
};

export const getArrayFromSearchParams = (searchParams: ReadonlyURLSearchParams | null) =>
  Array.from(searchParams?.keys() || []).flatMap(key =>
    searchParams?.getAll(key).map(value => `${key}=${value}`)
  ) as string[];
