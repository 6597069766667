import getFavoritesListName from "src/services/favorites/helpers/getFavoritesListName";
import { FavoriteActionType, ShoppingList } from "src/services/favorites/types";
import { LocalizeFunc } from "src/services/localize/useLocalizeService";

export interface Params {
  lists: ShoppingList[] | null;
  listIds: string[];
  actionType: FavoriteActionType;
}

const getFavoriteNotificationText = (
  params: Params,
  localize: LocalizeFunc,
) => {
  const { actionType, lists, listIds } = params;
  const isAdding = actionType === 'add';

  if (listIds.length > 1) {
    return localize(isAdding ? 'favorites.added_to_lists' : 'favorites.removed_from_lists');
  } else {
    const selectedListId = listIds[0];
    const list = lists?.find(l => l.id === selectedListId) || null;
    const listName = `"${getFavoritesListName(list, localize)}"`;
    const actionText = localize(isAdding ? 'favorites.added_to_list' : 'favorites.removed_from_list');

    return `${actionText} ${listName}`;
  }
};

export default getFavoriteNotificationText;
