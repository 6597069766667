import { ProductRetailerCardsQuery, UberProductStore } from "src/__generated__/graphql";
import { RetailChain } from "src/api/types";
import { Delivery } from "src/modules/delivery";
import { LocalizeFunc } from "src/services/localize/useLocalizeService";
import { getChainsMap } from "../../../utils/chains/getChainsMap";
import { ProductData } from "../hooks/useProductRetailInfoLists";
import { RetailInfoLists } from "../types";
import getRetailInfo, { SimpleTimeWindow } from "./getRetailInfo";
import { getUtmQueryString } from "./getUtmQueryString";

interface Params {
  retailCardsData?: ProductRetailerCardsQuery;
  deliveryType: Delivery['type'] | null;
  retailChains: RetailChain[];
  deliveryAsQueryStringForLink: string;
  productData: ProductData;
  language: string;
  localize: LocalizeFunc;
}

export const getRetailInfoListsForProduct = (params: Params) => {
  const {
    productData,
    retailChains,
    retailCardsData,
    deliveryType,
    deliveryAsQueryStringForLink,
    language,
    localize,
  } = params;
  const productChains = retailCardsData?.uberProducts.details.stores || [];
  const cartItemsCounts = retailCardsData?.carts.items || [];
  const nearestSlots = retailCardsData?.deliverySchedules.nearest || [];

  const retailChainsMap = getChainsMap(retailChains);

  const cartItemsCountsMap = cartItemsCounts.reduce<Record<string, number>>((acc, item) => ({
    ...acc,
    [item.chainId]: item.count,
  }), {});

  const nearestSlotsMap = nearestSlots.reduce<Record<string, SimpleTimeWindow>>((acc, {
    chainId,
    slot,
  }) => {
    if (slot) {
      return {
        ...acc,
        [chainId]: {
          time_range: slot.timeRange,
          date: slot.date,
          end_ordering_time: slot.endOrderingTime,
        },
      };
    }

    return acc;
  }, {});

  const productInChainMap = productChains.reduce<Record<string, UberProductStore>>((acc, item) => ({
    ...acc,
    [item.chainId]: item,
  }), {});

  return retailChains.reduce<RetailInfoLists>((acc, { id: chainId }) => {
    const retailChain = retailChainsMap[chainId];
    if (!retailChain) {
      return acc;
    }

    const productChain = productInChainMap?.[chainId];
    const count = cartItemsCountsMap?.[chainId] || 0;
    const slot = nearestSlotsMap?.[chainId] || null;
    const isAvailable = productChain?.available || false;
    const price = productChain?.price || 0;
    //we use the chain-specific ean if it exists
    const ean = productChain?.ean || productData.ean;
    const specificRouteForLink = productData
      ? `products/${productData.slug}--${ean}`
      : '';

    const retailInfo = getRetailInfo({
      isAvailable,
      retailChain,
      cartItemsCount: count,
      timeWindow: slot,
      deliveryType,
      deliveryAsQueryStringForLink,
      specificRouteForLink,
      language,
      utmQueryString: getUtmQueryString('ubercatalog'),
      price,
      localize,
    });

    if (isAvailable) {
      acc.available.push(retailInfo);
    } else {
      acc.unavailable.push(retailInfo);
    }

    return acc;
  }, { available: [], unavailable: [] });
};
