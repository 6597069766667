import { ReactNode, useEffect } from "react";
import WithFadeIn from "src/components/WithFadeIn/WithFadeIn";
import StylishBox from "src/ui-kit/StylishBox/StylishBox";
import { useTheme } from "src/ui-kit/theme";
import { Typography } from "src/ui-kit/Typography";

export interface NotificationProps {
  slotButton?: ReactNode;
  slotIcon?: ReactNode;
  dataMarker?: string;
  message: string;
  isOpen: boolean;
  showingDelay?: number;
  onClose: () => void;
}

const Notification = (props: NotificationProps) => {
  const { slotButton, slotIcon, dataMarker, message, isOpen, showingDelay = 5000, onClose } = props;
  const theme = useTheme();

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(onClose, showingDelay);

      return () => clearTimeout(timer);
    }
  }, [isOpen, showingDelay, onClose]);

  if (!isOpen) {
    return null;
  }

  return (
    <WithFadeIn>
      <StylishBox
        elementProps={{
          role: 'alert',
          "aria-labelledby": "notification",
        }}
        dataMarker={dataMarker}
        className='Notification'
        sbs={{
          display: 'inline-flex',
          alignItems: 'center',
          p: '10px 16px',
          backgroundColor: theme.base.color.white,
          minWidth: {
            general: '300px',
            phone: 'auto',
          },
          width: {
            phone: 'calc(100% - 32px)',
          },
          maxWidth: '364px',
          minHeight: '44px',
          pointerEvents: 'all',
          boxShadow: '0px 3px 16px 0px rgba(0, 0, 0, 0.08)',
        }}
      >
        {
          slotIcon && (
            <StylishBox
              element="span"
              sbs={{
                mr: '11px',
                lineHeight: '1',
              }}
            >
              {slotIcon}
            </StylishBox>
          )
        }

        <Typography variant='caption1' sbs={{ mr: '16px' }} ellipsisTextRows={2}>
          {message}
        </Typography>

        {slotButton && (
          <StylishBox
            sbs={{
              mr: '0',
              ml: 'auto',
            }}
          >
            {slotButton}
          </StylishBox>
        )}
      </StylishBox>
    </WithFadeIn>
  );
};

export default Notification;
