import { HEADER_HEIGHT, HEADER_HEIGHT_PHONE } from "src/data/constants";
import StylishBox from "src/ui-kit/StylishBox/StylishBox";
import { useTheme } from "src/ui-kit/theme";
import FavoriteNotification from "../FavoriteNotification/FavoriteNotification";

interface Props {
  additionalTopMargin?: number;
}

const NotificationsWrapper = (props: Props) => {
  const {
    additionalTopMargin = 0,
  } = props;
  const theme = useTheme();

  return (
    <StylishBox
      className="NotificationsWrapper"
      sbs={{
        position: 'fixed',
        right: '0',
        zIndex: theme.base.zIndex.notification,
        width: '100%',
        top: {
          general: `${HEADER_HEIGHT}px`,
          tablet: `${HEADER_HEIGHT + additionalTopMargin}px`,
          phone: `${HEADER_HEIGHT_PHONE + additionalTopMargin}px`,
        },
        pointerEvents: 'none',
      }}
    >
      <StylishBox
        sbs={{
          maxWidth: '1405px',
          width: '100%',
          pr: {
            general: '32px',
            phone: '0',
          },
          m: '0 auto',
          mt: {
            general: '16px',
            desktop: '24px',
          },
          display: 'flex',
          justifyContent: {
            general: 'flex-end',
            phone: 'center',
          },
        }}
      >
        <FavoriteNotification />
      </StylishBox>
    </StylishBox>
  );
};

export default NotificationsWrapper;
