import { ZAKAZ_CAREERS_URL } from "src/data/constants";
import getExternalLinks, { MenuLinkData } from "src/data/externalLinks";
import { sendHrFooterClick } from "src/utils/enhancedEcommerce";

interface FooterExternalLink extends MenuLinkData {
  onClick?(): void;
  dataMarker?: string;
}

export const getFooterExternalLinks = (language: string, country: string): FooterExternalLink[] => {
  const footerSpecificExternalLinks = {
    ua: [
      {
        href: ZAKAZ_CAREERS_URL,
        label: 'link_vacancies',
        onClick: sendHrFooterClick,
        dataMarker: 'Link Vacancies Footer',
      },
    ],
  };

  return [
    ...footerSpecificExternalLinks[country],
    ...getExternalLinks(language, country),
  ];
};

export const getHelpUrlLink = (language: string) => {
  return `https://help.zakaz.ua/hc/${language === 'en' ? 'en-us' : language}#main-content`;
};

export const getFooterInternalLinks = (language: string, country: string): MenuLinkData[] => {
  const links: Record<string, MenuLinkData[]> = {
    ua: [
      {
        label: 'questions_answers',
        href: getHelpUrlLink(language),
      },
      {
        label: 'pages_contacts',
        href: `/${language}/contacts/`,
      },
      {
        label: 'pages_policy',
        href: `/${language}/policy/`,
      },
      {
        label: 'pages_terms',
        href: `/${language}/terms/`,
      },
    ],
  };

  return links[country];
};
