import { useContext } from "react";
import FavoritesServiceContext from "./FavoritesContext";

const useFavoritesService = () => {
  const context = useContext(FavoritesServiceContext);

  if (!context) {
    throw new Error('FavoritesServiceContext is used without provider');
  }

  return context;
};

export default useFavoritesService;
