import cn from 'classnames';
import { DesktopSwitchLocaleStyled } from './DesktopSwitchLocale.styles';
import useSwitchLocale from './useSwitchLocale';

const DesktopSwitchLocale = () => {
  const {
    currentLanguage,
    theme,
    localize,
    links,
  } = useSwitchLocale();

  return (
    <DesktopSwitchLocaleStyled
      label={localize(`lang_${currentLanguage}`)}
      dataMarker="lang"
      theme={theme}
    >
      {links.map(link => (
        <div
          key={link.language}
          className="DesktopSwitchLocale__menuItem"
        >
          <a
            href={link.href}
            onClick={link.onClick}
            className={cn('DesktopSwitchLocale__menuLink', {
              DesktopSwitchLocale__menuLinkActive: link.isActive,
            })}
            data-marker={`lang-link-${link.language}`}
          >
            <span className='DesktopSwitchLocale__linkText'>
              {link.title}
            </span>
          </a>
        </div>
      ))}
    </DesktopSwitchLocaleStyled>
  );
};

export default DesktopSwitchLocale;
