import dynamic from "next/dynamic";
import FlexList from "src/components/FlexList/FlexList";
import StylishBox from "src/ui-kit/StylishBox/StylishBox";
import { RetailInfo, UnavailableListLabels } from "../../types";
import RetailsUnavailableLabel from "../UnavailableLabel/RetailsUnavailableLabel";

const RetailInfoCard = dynamic(
  () => import('../RetailInfoCard/RetailInfoCard'),
  { ssr: false },
);

interface RetailsInfoUnavailableListProps {
  items: RetailInfo[];
  onRetailInfoCardClick: (isAvailable: boolean, chainId: string) => void;
  onClickOpenAm: () => void;
  size: 'small' | 'medium' | 'large';
  unavailableListLabels: UnavailableListLabels;
}

const RetailsInfoUnavailableList = (props: RetailsInfoUnavailableListProps) => {
  const { onClickOpenAm, onRetailInfoCardClick, items, size, unavailableListLabels } = props;

  return (
    <StylishBox className="RetailsInfoUnavailableList" sbs={{ mt: '32px' }}>
      <RetailsUnavailableLabel
        onClick={onClickOpenAm}
        label={unavailableListLabels.label}
        buttonLabel={unavailableListLabels.button}
      />

      <div data-marker='Unavailable Retails'>
        <FlexList
          size={size}
          data={items}
          renderItem={(retailInfo) => (
            <RetailInfoCard
              isAvailable={false}
              size={size}
              retailInfo={retailInfo}
              onClick={() => onRetailInfoCardClick(false, retailInfo.id)}
              dataStatus='inactive'
            />
          )}
        />
      </div>
    </StylishBox>
  );
};

export default RetailsInfoUnavailableList;
