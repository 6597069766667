import { gql } from "src/__generated__/gql";

export const GET_PRODUCT_RETAIL_CARDS_INFO = gql(`
query ProductRetailerCards(
  $chainIds: [String!]!
  $storeIds: [String!]!
  $deliveryMethod: String!
  $clientCoords: String
  $clientSettlementId: String
  $productEan: String!
  $productTags: [String!]!
) {
  	uberProducts {
      details(
        ean: $productEan
        storeIds: $storeIds
        storesAvailable: true
      ) {
        mainEan
        stores {
          chainId
          storeId
          available
          price
          ean
        }
    }
  }
	deliverySchedules {
    nearest(
      nearestAcrossChain: true
      storeIds: $storeIds
      deliveryMethod: $deliveryMethod
      clientCoords: $clientCoords
      clientSettlementId: $clientSettlementId
      slotTypes: ["open"]
      productTags: $productTags
    ) {
      chainId
      slot {
        id
        date
        timeRange
        endOrderingTime
        isOpen
      }
    }
	}
  carts {
    items(chainIds: $chainIds) {
      chainId
      count
    }
  }
}`);
